<template>
    <div v-show="modalVisible" class="modal-turbo-search fixed flex justify-center top-0 left-0 h-full w-full">
        <div class="absolute top-0 left-0 w-full h-full bg-black opacity-75 z-20" />

        <div class="relative mt-8 z-30">
            <SearchTypeSelection
                @setInitialSearchSelection="setInitialSearchSelection"
                @selectionChange="selectionChange"
            />

            <div>
                <CloseButton @close="closeModal" />

                <SearchInput
                    :resetInputField="!!mergedResults.length"
                    @searchValueChanged="search"
                    @resetSearchResults="resetSearchResults"
                />
            </div>

            <div class="bg-white z-50 rounded-lg mt-6 shadow-xl">
                <div
                    v-for="(item, index) in mergedResults"
                    :key="item.search.uuid"
                    class="flex items-center justify-between px-4 h-24"
                    :class="{ 'border-b': mergedResults.length >= index + 2 }"
                >
                    <div v-if="item.name !== 'key'" class="flex flex-col">
                        <p>{{ item.search.name }} {{ item.search.surname }}</p>
                        <span v-if="item.name === 'client'" class="text-xs text-gray-400">{{ $t('search.client') }}</span>
                        <span v-else class="text-xs text-gray-400">{{ $t('search.employee') }}</span>
                        <div class="flex flex-wrap hits text-gray-500">
                            <p
                                v-for="(hit, indexP) in item.search.hits"
                                :key="indexP"
                                class="mr-4 mt-1 text-xs"
                                v-html="hit"
                            />
                        </div>
                    </div>
                    <div v-else class="flex flex-col">
                        <p>{{ item.search.client_name }} {{ item.search.client_surname }}</p>
                        <span class="text-xs text-gray-400">{{ $t('search.key') }}</span>
                        <div class="flex flex-wrap hits text-gray-500">
                            <p
                                v-for="(hit, indexP) in item.search.hits"
                                :key="indexP"
                                class="mr-4 mt-1 text-xs"
                                v-html="hit"
                            />
                        </div>
                    </div>
                    <div>
                        <router-link v-if="item.name === 'client'" :to="`/clients/${item.search.uuid}/dashboard`">
                            <fa-icon :icon="['fad', 'share-all']" class="mr-3 mt-1 text-xl cursor-pointer text-gray-500 hover:text-gray-600" />
                        </router-link>
                        <router-link v-if="item.name === 'key'" :to="`/keys`">
                            <fa-icon :icon="['fad', 'share-all']" class="mr-3 mt-1 text-xl cursor-pointer text-gray-500 hover:text-gray-600" />
                        </router-link>
                        <fa-icon v-if="item.name === 'key'" :icon="['fad', 'share-all']" class="mr-3 mt-1 text-xl text-gray-500" />
                        <router-link v-if="item.name === 'employee'" :to="`/employees/${item.search.uuid}/information`">
                            <fa-icon :icon="['fad', 'share-all']" class="mr-3 mt-1 text-xl cursor-pointer text-gray-500 hover:text-gray-600" />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import algoliasearch from 'algoliasearch/lite';
import map from 'lodash/map';
import flatten from 'lodash/flatten';

const algoliaClient = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_SECRET);
const clientAlgoliaIndex = algoliaClient.initIndex('clients');
const employeesAlgoliaIndex = algoliaClient.initIndex('employees');
const keysAlgoliaIndex = algoliaClient.initIndex('keys');

export default {
    components: {
        SearchTypeSelection: () => import('./components/SearchTypeSelection.vue'),
        SearchInput:         () => import('./components/SearchInput.vue'),
        CloseButton:         () => import('./components/CloseButton.vue'),
    },

    props: {
        modalVisible: {
            type:    Boolean,
            default: false,
        },
    },

    data() {
        return {
            masterUuid:         '',
            searchValue:        '',
            clientsResults:     [],
            keysResults:        [],
            employeesResults:   [],
            mergedResults:      [],
            clientsSelection:   true,
            employeesSelection: true,
            keysSelection:      true,
        };
    },

    watch: {
        $route() {
            this.$emit('close');
        },
    },

    created() {
        this.masterUuid = this.$store.state.user.user.master_uuid;
        window.addEventListener('keydown', this.escPressed);
    },

    methods: {
        escPressed(e) {
            if (e.key === 'Escape') {
                e.preventDefault();
                this.closeModal();
            }
        },

        setInitialSearchSelection(payload) {
            this.clientsSelection = payload.clients;
            this.employeesSelection = payload.employees;
            this.keysSelection = payload.keys;
        },

        selectionChange(payload) {
            this[`${payload.type}Selection`] = payload.value;

            if (this.searchValue !== '') {
                this.search(this.searchValue);
            }
        },

        search(query) {
            this.searchValue = query;
            this.mergedResults = [];
            if (this.clientsSelection && this.userCan('read clients')) {
                this.$wait.start('searching.clients');
                this.clientSearch();
            }
            if (this.employeesSelection && this.userCan('read employees')) {
                this.$wait.start('searching.employees');
                this.employeesSearch();
            }
            if (this.keysSelection && this.userCan('read keys')) {
                this.$wait.start('searching.keys');
                this.keysSearch();
            }
        },

        async clientSearch() {
            const { hits } = await clientAlgoliaIndex.search(this.searchValue, {
                attributesToRetrieve:         ['uuid', 'name', 'surname'],
                restrictSearchableAttributes: ['name', 'surname'],
                attributesToHighlight:        ['name', 'surname'],
                filters:                      `user_uuid:"${this.masterUuid}"`,
                hitsPerPage:                  6,
            });

            let tte2 = map(hits, '_highlightResult');
            tte2 = tte2.map(item => {
                delete item.uuid;
                return item;
            });

            tte2 = tte2.map(element => map(element, a => (Array.isArray(a) ? a.map(b => map(b, 'value')) : a.value)));

            tte2 = tte2.map(a => flatten(a));
            tte2 = tte2.map(a => flatten(a));
            tte2 = tte2.map(element => element.filter(a => !!a));
            tte2 = tte2.map(element => element.filter(a => a.includes('<em>')));

            hits.forEach((value, key) => {
                value.hits = tte2[key];
            });

            this.clientsResults = hits;

            this.$wait.end('searching.clients');
            this.mergeResults(hits, 'client');
        },

        async keysSearch() {
            const { hits } = await keysAlgoliaIndex.search(this.searchValue, {
                attributesToRetrieve:         ['uuid', 'name', 'client_name', 'client_surname'],
                restrictSearchableAttributes: ['name', 'client_name', 'client_surname'],
                attributesToHighlight:        ['name', 'client_name', 'client_surname'],
                filters:                      `user_uuid:"${this.masterUuid}"`,
                hitsPerPage:                  6,
            });

            let tte2 = map(hits, '_highlightResult');
            tte2 = tte2.map(element => map(element, a => (Array.isArray(a) ? a.map(b => map(b, 'value')) : a.value)));

            tte2 = tte2.map(a => flatten(a));
            tte2 = tte2.map(a => flatten(a));
            tte2 = tte2.map(element => element.filter(a => !!a));
            tte2 = tte2.map(element => element.filter(a => a.includes('<em>')));

            hits.forEach((value, key) => {
                value.hits = tte2[key];
            });

            this.keysResults = hits;
            this.$wait.end('searching.keys');
            this.mergeResults(hits, 'key');
        },

        async employeesSearch() {
            const { hits } = await employeesAlgoliaIndex.search(this.searchValue, {
                attributesToRetrieve:         ['uuid', 'email', 'name', 'surname'],
                restrictSearchableAttributes: ['name', 'email', 'surname'],
                attributesToHighlight:        ['name', 'email', 'surname'],
                filters:                      `user_uuid:"${this.masterUuid}"`,
                hitsPerPage:                  6,
            });

            let tte2 = map(hits, '_highlightResult');
            tte2 = tte2.map(element => map(element, a => (Array.isArray(a) ? a.map(b => map(b, 'value')) : a.value)));

            tte2 = tte2.map(a => flatten(a));
            tte2 = tte2.map(a => flatten(a));
            tte2 = tte2.map(element => element.filter(a => !!a));
            tte2 = tte2.map(element => element.filter(a => a.includes('<em>')));

            hits.forEach((value, key) => {
                value.hits = tte2[key];
            });

            this.employeesResults = hits;

            this.$wait.end('searching.employees');
            this.mergeResults(hits, 'employee');
        },

        mergeResults(arr, from) {
            if (this.mergedResults.length === 6) return;
            arr.forEach(item => {
                if (this.mergedResults.length === 6) return;
                this.mergedResults.push({ search: item, name: from });
            });
        },

        resetSearchResults() {
            this.clientsResults = [];
            this.keysResults = [];
            this.mergedResults = [];
        },

        closeModal() {
            window.removeEventListener('keydown', this.escPressed);
            this.resetSearchResults();
            this.$emit('close');
        },
    },
};
</script>
<style>
.modal-turbo-search {
    z-index: 99998;
}

.modal-turbo-search-popup {
    z-index: 99999 !important;
}

.hits em {
    font-style: normal;
    background-color: #dcf5ff;
}
</style>
